import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'
import { useTranslations } from '../hooks/use-translations'

import Photo360Gif from '../assets/images/solutions/execution-phase/photo360.gif'

import * as s from '../pages/news.module.scss'

const ExecutionPhase = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)
  return (
    <>
      <SEO
        title="Execution phase"
        description="During the project's construction stage, progress tracking is essential to ensure tasks are completed on time. Regular QA/QC inspections are conducted to maintain quality standards, while schedule monitoring helps identify deviations and allows for timely adjustments to keep the project on track."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            May 13, 2024
          </Typography>
          <Typography variant="h1" color="blue" size={40} mb={48}>
            Execution phase
          </Typography>
          <Typography variant="body1" size={18}>
            During the project's construction stage, progress tracking is
            essential to ensure tasks are completed on time. Regular QA/QC
            inspections are conducted to maintain quality standards, while
            schedule monitoring helps identify deviations and allows for timely
            adjustments to keep the project on track.
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Work scheduling and construction process visualization
              </Typography>
              <Typography variant="body1" size={18}>
                SIGNAX digital engineers utilize Work Scheduling and
                Construction Process Visualization services for customers. By
                integrating the project Work Breakdown Structure (WBS) schedule
                with the BIM model, stakeholders gain a comprehensive
                visualization of the construction lifecycle, enabling precise
                progress tracking. BIM engineers compare construction progress
                to the project schedule using the SIGNAX TOOLS module, allowing
                stakeholders to track milestones and identify potential delays.
                The visualization aspect not only enhances project communication
                but also aids in forecasting project metrics, such as completion
                timelines, fostering informed decision-making throughout the
                construction process. You can try the free version of the TOOLS
                module in the Autodesk store or consider the TOOLS PRO version
                with an extended list of features.
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/execution-phase/image-1.png"
                alt="Work scheduling and construction process visualization"
                title="Work scheduling and construction process visualization"
                placeholder="blurred"
              />
            </div>
          </div>
          <Typography variant="h2" size={24} mt={48} mb={16}>
            Construction Data integration
          </Typography>
          <Typography variant="body1" size={18} mb={32}>
            The 4D Construction Data integration service enhances elements of
            the BIM model with construction data properties. That includes work
            trade information, dates of implementation, contractors' legal
            names, and other essential construction stage data. SIGNAX provides
            weekly and monthly construction reports. Through the DASHBOARD
            module, construction data is presented in easily understandable
            graphs, empowering teams for efficient decision-making and proactive
            project management throughout the construction stage.
          </Typography>
          <div className={s.contentWrap}>
            <StaticImage
              width={564}
              src="../assets/images/solutions/execution-phase/image-2.png"
              alt="Construction Data integration"
              title="Construction Data integration"
              placeholder="blurred"
            />
            <StaticImage
              width={564}
              src="../assets/images/solutions/execution-phase/image-3.png"
              alt="Construction Data integration 2"
              title="Construction Data integration 2"
              placeholder="blurred"
            />
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Construction project documentation
              </Typography>
              <Typography variant="body1" size={18}>
                The Construction Progress Tracking service is a practical
                solution for project monitoring. SIGNAX digital engineers
                generate visual reports on performed work, ensuring accurate
                documentation of progress. Furthermore, our INSPECTION module
                allows easy comparison of design stage BIM models with on-site
                conditions for comprehensive quality control. The project team
                and construction project stakeholders can further utilize a
                platform for viewing, comparing, and storing panoramic imagery,
                comments, and inquiries linked to the panoramic images or floor
                plans.
              </Typography>
            </div>
            <div>
              <img
                width={564}
                src={Photo360Gif}
                alt="Construction project documentation"
                title="Construction project documentation"
                loading="lazy"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                As-built model development
              </Typography>
              <Typography variant="body1" size={18}>
                As-built model development service ensures alignment with the
                on-site realities of the project. As-built BIM model is created
                by leveraging the design stage BIM model with Point cloud data
                or with shop drawings and as-built documentation. Our BIM
                engineers could develop an as-built BIM model from scratch as an
                alternative option. Сases may differ and require different
                solutions, and we are here to help you find the best one for
                your project. Collision checks guarantee seamless
                multi-disciplinary integration of the model. Benefits include
                weekly/monthly status reports, better progress visualization
                during meetings, and empowering contractors and subcontractors'
                teams with practical, precise information for further project
                coordination and execution.
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/execution-phase/image-4.png"
                alt="As-built model development"
                title="As-built model development"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate('/bim-services/')}
          >
            Other BIM services
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default ExecutionPhase

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
